<template>
  <div id="app" @click="handleAppClick">
    <router-view/>
  </div>
</template>
<script>
export default {
  name:'App',
  methods:{
    handleAppClick(){
      console.log('app')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
#app{
  padding: 10px;
}
</style>
